<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import TheIcon from '@/components/shared/the-icon.vue'
import { setModalState } from '~/services/modal'
import type { IBank } from '~/shared/interfaces'

const profileStore = useProfileStore()
const { $event, $listen, $api } = useNuxtApp()

const bankAccountDivs = ref<HTMLDivElement[]>([])
const { organisationId } = storeToRefs(profileStore)
const model = defineModel<string>()
const bankAccountDetailsModel = defineModel<IBank>('bankDetails')

async function fetchBankAccounts() {
  const response =
    await $api.banking.bankAccounts.getPreviouslyUsedBankAccounts(
      organisationId.value!,
    )

  // SIDE EFFECT: Not the best way, but right now the UI has limitations
  const selectableBanks = response.data.filter((d) => !!d.provider_id)
  if (selectableBanks.length === 1) {
    selectBankAccount(selectableBanks[0])
  }
  return response
}

const { data: banksAccounts } = useQuery({
  queryKey: ['get-bank-accounts', organisationId],
  queryFn: fetchBankAccounts,
  refetchOnMount: 'always',
  select(data) {
    return data.data
  },
})

const selectableBankAccounts = computed(() => {
  if (!banksAccounts.value) return []
  return banksAccounts.value.filter((b) => !!b.provider_id).reverse()
})

const hasBanksFromXeroOrQuickBooksWithoutProviderId = computed(() => {
  if (!banksAccounts.value || banksAccounts.value.length < 1) return false
  return banksAccounts.value?.some(
    (bank) =>
      !bank.provider_id &&
      (bank.accounting_platform_name === 'XERO' ||
        bank.accounting_platform_name === 'QUICKBOOKS'),
  )
})

const selectBankAccount = (bank: IBank, scrollInto?: boolean) => {
  $event('track:mixpanel', {
    event: 'Open Banking Account Selected',
    data: {
      ...bank,
    },
  })
  model.value = bank.id
  bankAccountDetailsModel.value = bank

  if (scrollInto) {
    nextTick(() => {
      scrollToBankAccountElement(model.value!)
    })
  }

  $event('select:bank', {
    trigger: true,
    selectBank: bank,
  })
}

function scrollToBankAccountElement(bankID: IBank['id']) {
  // const element = document.querySelector(`[data-id="${bankID}"]`)

  const element = bankAccountDivs.value.find(
    (item) => item.dataset.id === bankID,
  )

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    })
  }
}

const addAccount = () => {
  if (hasBanksFromXeroOrQuickBooksWithoutProviderId.value) {
    setModalState({ isOpen: true, type: 'addBankAdvance' })
  } else {
    setModalState({ isOpen: true, type: 'addbank' })
  }
}

const formatNumber = (originalNumber: string) => {
  if (originalNumber) {
    const parts = originalNumber.match(/\d{2}/g)
    return parts ? parts.join('-') : ''
  }
}

$listen('bank:added', (_bank) => {
  model.value = undefined
  if (banksAccounts.value) {
    const copy = [...banksAccounts.value]
    const newestSelectableBankAccount = copy
      .filter((b) => !!b.provider_id)
      .pop()
    newestSelectableBankAccount &&
      selectBankAccount(newestSelectableBankAccount, true)
  }
})
</script>

<template>
  <div>
    <div class="flex flex-col">
      <template v-if="selectableBankAccounts.length > 0">
        <ScrollArea
          v-if="selectableBankAccounts.length"
          :class="selectableBankAccounts.length >= 3 ? 'h-[200px]' : ''"
          class="w-full"
        >
          <div
            v-for="(bank, idx) in selectableBankAccounts"
            ref="bankAccountDivs"
            :key="bank.id"
            :data-id="bank.id"
            :class="model === bank.id ? 'bg-gray-50 px-1' : ''"
            :data-test-id="`bank-account-${idx}`"
            @click.prevent="
              () => {
                if (!bank.provider_id) return
                selectBankAccount(bank)
              }
            "
          >
            <div class="flex items-center gap-x-3 border-b py-3">
              <div class="inline-flex items-center gap-x-3">
                <svg
                  v-if="model === bank.id"
                  :class="[!bank.provider_id ? 'opacity-50' : 'opacity-100']"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="7.5" fill="white" stroke="#E2E8F0" />
                  <circle cx="8" cy="8" r="4" fill="black" />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  :class="[!bank.provider_id ? 'opacity-50' : 'opacity-100']"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="7.5"
                    stroke="#D0D5DD"
                  />
                </svg>
                <img
                  v-if="bank.provider_logo_url"
                  :src="bank.provider_logo_url"
                  alt=""
                  class="h-10 w-10 rounded-full border p-1"
                />

                <div
                  v-else
                  class="flex h-10 w-10 items-center justify-center rounded-full border"
                >
                  <TheIcon icon-name="bank" :size="'s'" />
                </div>
              </div>
              <div class="flex-1">
                <div>
                  <p class="pb-1 text-sm font-medium text-primary">
                    {{ bank.bank_name }}
                    <span v-if="bank.account_nickname">
                      ({{ bank.account_nickname }})
                    </span>
                  </p>
                  <p
                    v-if="bank.provider_id"
                    class="text-sm font-normal text-[#475467]"
                  >
                    {{ bank.account_number }}
                    <span v-show="!!bank.sort_code">|</span>
                    {{ bank.sort_code && formatNumber(bank.sort_code) }}
                  </p>
                  <span
                    v-if="bank.accounting_platform_name && bank.provider_id"
                    class="inline-flex rounded-[2.125rem] px-1 text-xs leading-5"
                    :class="{
                      'bg-[#A0E6FF] text-[#056588]':
                        bank.accounting_platform_name === 'XERO',
                      'bg-[#dcfce7] text-[#15803d]':
                        bank.accounting_platform_name === 'QUICKBOOKS',
                    }"
                    >{{ bank.accounting_platform_name }}</span
                  >
                </div>

                <div
                  v-if="!bank.provider_id"
                  class="mr-4 flex items-end justify-between"
                >
                  <template v-if="bank.accounting_platform_name">
                    <span
                      class="inline-flex rounded-[2.125rem] px-1 text-xs leading-5"
                      :class="{
                        'bg-[#A0E6FF] text-[#056588]':
                          bank.accounting_platform_name === 'XERO',
                        'bg-[#dcfce7] text-[#15803d]':
                          bank.accounting_platform_name === 'QUICKBOOKS',
                      }"
                      >{{ bank.accounting_platform_name }}</span
                    >
                  </template>

                  <button
                    class="leading flex items-end py-0 text-xs font-medium text-[#3F558E] underline"
                    @click="addAccount"
                  >
                    Add account details
                  </button>
                </div>
              </div>
            </div>
            <template v-if="banksAccounts?.length"> </template>
          </div>
        </ScrollArea>

        <p
          v-if="selectableBankAccounts?.length"
          class="py-3 text-center text-sm font-normal text-primary"
        >
          Need to make this payment using a different bank account?
        </p>
      </template>
      <p
        v-if="!selectableBankAccounts?.length"
        class="py-3 text-center text-sm font-normal text-primary"
      >
        You have not added any bank accounts. Add one to start making payments.
      </p>

      <Button
        variant="outline"
        class="inline-flex w-full gap-x-2 text-sm font-medium text-primary"
        @click.prevent="addAccount"
      >
        Add a new bank account
      </Button>
    </div>
  </div>
</template>
