import { useQuery } from '@tanstack/vue-query'

export function getStripeCardsQuery() {
  const { $api } = useNuxtApp()
  const profileStore = useProfileStore()
  const { organisationId } = storeToRefs(profileStore)
  const enableQuery = computed(() => !!organisationId.value)

  return useQuery({
    queryKey: ['get-stripe-keys', { organisationId: organisationId.value }],
    queryFn: () => $api.banking.cards.getStripeCards(organisationId.value!),
    enabled: enableQuery,
    select(data) {
      return data.data
    },
  })
}
