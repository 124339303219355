<script setup lang="ts">
import { getStripeCardsQuery } from '~/queries/cards.query'
import type { OurStripeCard } from '~/types/models/cards.model'

interface Props {
  amount: number
}

const { CardPay } = usePaymentComposable()
const model = defineModel<OurStripeCard['id']>()
const props = defineProps<Props>()
const { data: cardsData } = getStripeCardsQuery()

const cardList = computed(() => cardsData.value || [])

const calculatedAmounts = computed(() => {
  return CardPay.calculateAmountsForCardPay(props.amount)
})
</script>

<template>
  <div>
    <div class="w-full">
      <template v-if="cardList.length > 0">
        <p class="pb-[6px] text-sm font-medium text-primary">
          Select credit card
        </p>

        <Select v-model="model" class="capitalize">
          <SelectTrigger>
            <SelectValue placeholder="Select card" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                v-for="card in cardList"
                :key="card.id"
                :value="card.id"
              >
                {{ card.brand }} |
                {{ card.last_four_digits }}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>

        <div class="mt-6 flex flex-col gap-y-3">
          <p class="text-sm font-medium text-primary">Transaction overview</p>
          <div class="flex items-center justify-between">
            <p class="text-sm font-normal text-primary">Invoice amount</p>
            <p class="text-sm font-medium text-[#334155]">
              {{
                new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                  minimumFractionDigits: 2,
                }).format(calculatedAmounts.base_amount || 0)
              }}
            </p>
          </div>
          <div class="flex items-center justify-between">
            <p class="text-sm font-normal text-primary">
              Processing fee (2.9%)
            </p>
            <p class="text-sm font-medium text-[#334155]">
              {{
                new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: `GBP`,
                  minimumFractionDigits: 2,
                }).format(calculatedAmounts.processing_fee || 0)
              }}
            </p>
          </div>
          <div class="flex items-center justify-between">
            <p class="text-sm font-normal text-primary">Total</p>
            <p class="text-sm font-medium text-[#334155]">
              {{
                new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: `GBP`,
                  minimumFractionDigits: 2,
                }).format(calculatedAmounts.total_amount || 0)
              }}
            </p>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="text-center text-sm text-slate-500">
          You do not have any credit cards. Please proceed to add a new card.
        </div>
      </template>

      <nuxt-link to="/settings/connections?active=credit-cards">
        <Button variant="outline" class="mt-4 w-full"
          >Add new credit card</Button
        >
      </nuxt-link>
    </div>
  </div>
</template>
